.avatar {
  position: fixed;
  overflow: hidden;
  z-index: -2;
  --initial-transform: translateY(0); 
  animation: floating 3s ease-in-out infinite;
}

.avatar.about {
  height: 89vh;
  bottom: -8vh;
  left: 50%;
  --initial-transform: translateX(-50%) scale(1.2);
  transform: var(--initial-transform);
}

.avatar.skills {
  height: 89vh;
  bottom: -8vh;
  left: 50%;
  --initial-transform: translateX(-70%) scaleX(-1);
  transform: var(--initial-transform);
}

@media (max-width: 1100px) {
  .avatar.about {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
  .avatar.skills {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
}

@media (max-width: 700px) {
  .avatar.about {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
  .avatar.skills {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
}

@media (max-width: 480px) {
  .avatar.about {
    z-index: -5;
    height: 90vh;
    bottom: -13vh;
  }
  .avatar.skills {
    z-index: -5;
    height: 90vh;
    bottom: -13vh;
  }
}

@keyframes floating {
  from { transform: var(--initial-transform) translateY(0); }
  65%  { transform: var(--initial-transform) translateY(15px); }
  to   { transform: var(--initial-transform) translateY(0); }
}